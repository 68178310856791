*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main_agency_team_page_outer_container .container .content_container{
    margin-left: 270px;
    margin-right: 15px;
}

.main_agency_team_page_outer_container .container .content_container h2{
    margin-bottom: 1.8rem;
}

.main_agency_team_page_outer_container .container .content_container .add_new_team_btn_container .add_new_team_btn{
    padding: 18px 2rem;
    background: none;
    border: none;
    color: #fff;
    background-color: #000;
    border-radius: 40px;
    cursor: pointer;
    margin-bottom: 2rem;
    margin-right: 1.5rem;
}

.main_agency_team_page_outer_container .container .content_container .add_new_team_btn_container .join_new_team_btn{
    padding: 18px 2rem;
    background: none;
    border: none;
    color: #fff;
    background-color: #000;
    border-radius: 40px;
    cursor: pointer;
    margin-bottom: 2rem;
}

.main_agency_team_page_outer_container .container .content_container .add_new_team_btn_container .add_new_team_btn .actual_link{
    text-decoration: none;
    color: #fff;
}

.main_agency_team_page_outer_container .container .content_container .add_new_team_btn_container .join_new_team_btn .actual_link{
    text-decoration: none;
    color: #fff;
}

.main_agency_team_page_outer_container .container .content_container .users_teams_container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 30px;
    margin-bottom: 2rem;
}

.main_agency_team_page_outer_container .container .content_container .users_teams_container .single_team{
    /* border: 1px solid rgb(212, 212, 212); */
    padding: 15px;
    border-radius: 30px;
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
    cursor: pointer;
    background-color: #f7f7f7;
}

.main_agency_team_page_outer_container .container .content_container .users_teams_container .single_team .link_to_agency_team_page{
    text-decoration: none;
    color: #000;
    margin-bottom: 0px;
}

.main_agency_team_page_outer_container .container .content_container .users_teams_container .single_team .link_to_agency_team_page h2{
    margin-bottom: 15px;
}

.main_agency_team_page_outer_container .container .content_container .users_teams_container .single_team:hover{
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.main_agency_team_page_outer_container .container .content_container .users_teams_container .single_team p{
    font-size: 17px;
    margin-bottom: 8px;
}