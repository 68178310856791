*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.client_requests_outer_main_container .container .content_container{
    margin-left: 270px;
    margin-right: 15px;
}


.client_requests_outer_main_container .container .content_container h2{
    margin-bottom: 2rem
}

.client_requests_outer_main_container .container .content_container .requests_container{
    display: flex;
    flex-direction: column;
}

.client_requests_outer_main_container .container .content_container .requests_container .single_request .link_to_request_detail{
    display: flex;
    flex-direction: column;
    padding: 15px;
    /* border: 1px solid rgb(212, 212, 212); */
    text-decoration: none;
    color: #000;
    margin-bottom: 30px;
    background-color: #f7f7f7;
}

.client_requests_outer_main_container .container .content_container .requests_container .single_request .link_to_request_detail:hover{
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.client_requests_outer_main_container .container .content_container .requests_container .single_request .link_to_request_detail h2{
    margin-bottom: 10px;
}

.client_requests_outer_main_container .container .content_container .requests_container .single_request .link_to_request_detail .request_short_description{
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 10px;
}

.client_requests_outer_main_container .container .content_container .requests_container .single_request .link_to_request_detail .request_date_posted{
    font-weight: 300;
    margin-bottom: 10px;
}

.client_requests_outer_main_container .container .content_container .requests_container .single_request .link_to_request_detail .request_status{
    font-weight: 300;
} 