*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main_outer_client_container_inside_agency_page .container .content_container{
    margin-left: 270px;
    margin-right: 15px;
}

.main_outer_client_container_inside_agency_page .container .content_container h2{
    margin-bottom: 10px;
}

.main_outer_client_container_inside_agency_page .container .content_container p{
    font-weight: 300;
    margin-bottom: 2rem;
    font-size: 18px;
}

.main_outer_client_container_inside_agency_page .container .content_container .add_new_client_objective_container .add_new_client_objective{
    margin-bottom: 2rem;
    border: none;
    background: none;
    cursor: pointer;
    color: #fff;
    background-color: #000;
    border-radius: 40px;
    padding: 20px;
}

.main_outer_client_container_inside_agency_page .container .content_container .add_new_client_objective_container .add_new_client_objective .actual_add_task_link_btn{
    color: #fff;
    text-decoration: none;
}

.main_outer_client_container_inside_agency_page .container .content_container .objectives_outer_container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-bottom: 2rem;
}

.main_outer_client_container_inside_agency_page .container .content_container .objectives_outer_container .single_objective{
    padding: 15px;
    /* border: 1px solid rgb(212, 212, 212); */
    cursor: pointer;
    display: flex;
    flex-direction: column;
    background-color: #f7f7f7;
    border-radius: 20px;
}

.main_outer_client_container_inside_agency_page .container .content_container .objectives_outer_container .single_objective:hover{
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.main_outer_client_container_inside_agency_page .container .content_container .objectives_outer_container .single_objective .objective_title{
    margin-bottom: 15px;
    font-size: 22px;
}

.main_outer_client_container_inside_agency_page .container .content_container .objectives_outer_container .single_objective .objective_description{
    font-weight: 400;
    line-height: 33px;
    margin-bottom: 18px;
    font-weight: 300;
}

.main_outer_client_container_inside_agency_page .container .content_container .objectives_outer_container .single_objective .objective_date{
    font-weight: 200;
    margin-bottom: 15px;
}

.main_outer_client_container_inside_agency_page .container .content_container .objectives_outer_container .single_objective .objective_status{
    font-weight: 300;
}