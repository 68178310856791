*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main_outer_task_container .container .content_container{
    margin-left: 270px;
    margin-right: 16px;
}

.main_outer_task_container .container .content_container h2{
    margin-bottom: 2rem;
    font-size: 2rem;
}

.main_outer_task_container .container .content_container .add_new_client_task_btn{
    background: none;
    border: none;
    background-color: #000;
    padding: 20px 3rem;
    cursor: pointer;
    border-radius: 30px;
    margin-bottom: 1rem;
}

.main_outer_task_container .container .content_container .add_new_client_task_btn .actual_btn_link{
    text-decoration: none;
    color: #fff;
}

.main_outer_task_container .container .content_container .tasks_container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.main_outer_task_container .container .content_container .tasks_container .single_task{
    /* border: 1px solid rgb(212, 212, 212); */
    padding: 22px;
    border-radius: 30px;
    border-radius: 30px;
    cursor: pointer;
    background-color: #f7f7f7;
}

.main_outer_task_container .container .content_container .tasks_container .single_task:hover{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
}

.main_outer_task_container .container .content_container .tasks_container .single_task h3{
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.main_outer_task_container .container .content_container .tasks_container .single_task p{
    font-size: 16px;
    margin-bottom: 15px;
}

.main_outer_task_container .container .content_container .tasks_container .single_task .task_short_description{
    line-height: 26px;
}