*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main_outer_client_team_detail_page .container .content_container{
    margin-left: 270px;
    margin-right: 16px;
}

.main_outer_client_team_detail_page .container .content_container .big_title{
    margin-bottom: 1.5rem;
}

.main_outer_client_team_detail_page .container .content_container .make_new_client_request_btn{
    border: none;
    background: none;
    background-color: #000;
    padding: 21px 2rem;
    cursor: pointer;
    border-radius: 30px;
    margin-bottom: 2rem;
}

.main_outer_client_team_detail_page .container .content_container .make_new_client_request_btn .actual_link{
    text-decoration: none;
    color: #fff;
}

.main_outer_client_team_detail_page .container .content_container .requests_container .single_request{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.main_outer_client_team_detail_page .container .content_container .requests_container .single_request .actual_request_detail_container{
    border: 1px solid rgb(212, 212, 212);
    padding: 15px;
    text-decoration: none;
    color: #000;
    margin-bottom: 25px;
}

.main_outer_client_team_detail_page .container .content_container .requests_container .single_request .actual_request_detail_container:hover{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
}

.main_outer_client_team_detail_page .container .content_container .requests_container .single_request .actual_request_detail_container .request_title{
    margin-bottom: 17px;
    font-size: 1.7rem;
}

.main_outer_client_team_detail_page .container .content_container .requests_container .single_request .actual_request_detail_container .request_short_description{
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 20px;
}

.main_outer_client_team_detail_page .container .content_container .requests_container .single_request .actual_request_detail_container .request_date_posted{
    font-size: 15px;
    font-weight: 300;
}