*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main_outer_sidenavbar_container .container {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    height: 100%;
    /* background-color: #f5f5f5;  */
}

.main_outer_sidenavbar_container .container .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    width: 250px;
    height: 100%;
    background-color: #f7f7f7;
    color: #000;
    /* background-color: #393e46; */
    padding-top: 20px;
    overflow-y: auto;
}

.main_outer_sidenavbar_container .container .logo {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 30px;
    color: #000;
    cursor: pointer;
}

.main_outer_sidenavbar_container .container .logo .home_link{
    text-decoration: none;
    color: #000;
}

.main_outer_sidenavbar_container .container .nav {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.main_outer_sidenavbar_container .container .nav li {
    margin-bottom: 10px;
}

.main_outer_sidenavbar_container .container .nav li .nav-icon{
    margin-right: 15px;
    font-size: 20px;
}

.main_outer_sidenavbar_container .container .nav a {
    display: block;
    padding: 12px 20px;
    color: #000;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.main_outer_sidenavbar_container .container .nav a:hover {
    /* background-color: #5c6773; */
    background-color: #eaeaea;
}

.main_outer_sidenavbar_container .container .nav .active {
    background-color: #6598d6;
}

.main_outer_sidenavbar_container .container .sidenavbar_top_navbar_container {
    margin-top: 0px;
    padding: 0px;
    margin-left: 250px;
    margin-bottom: 1rem;
}

.main_outer_sidenavbar_container .container .sidenavbar_top_navbar_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.main_outer_sidenavbar_container .container .sidenavbar_top_navbar_container .top_navbar_search_container{
    width: 70%;
}


.main_outer_sidenavbar_container .container .sidenavbar_top_navbar_container .top_navbar_search_container input{
    padding: 16px 1.5rem;
    border: 1px solid rgb(212, 212, 212);
    font-size: 15px;
    font-weight: 300;
    width: 100%;
    background: none;
}

.main_outer_sidenavbar_container .container .sidenavbar_top_navbar_container .top_navbar_current_user_container{
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.main_outer_sidenavbar_container .container .sidenavbar_top_navbar_container .top_navbar_current_user_container .current_user_leftside .current_user_img{
    width: 50px;
    height: 50px;
    margin-right: 15px;
    cursor: pointer;
    border-radius: 40px;
}

.main_outer_sidenavbar_container .container .sidenavbar_top_navbar_container .top_navbar_current_user_container .current_user_rightside h4{
    margin-right: 10px;
    font-weight: 300;
} 

.main_outer_sidenavbar_container .container .sidenavbar_top_navbar_container .top_navbar_current_user_container .current_user_rightside h4 .username_link{
    text-decoration: none;
    color: #000;
}

.main_outer_sidenavbar_container .container .main_actual_content_container{
    margin-left: 255px;
}