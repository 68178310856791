*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.client_folders_main_outer_container .container .content_container{
    margin-left: 270px;
    margin-right: 15px;
}

.client_folders_main_outer_container .container .content_container .add_new_folder_btn_container{
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.client_folders_main_outer_container .container .content_container .add_new_folder_btn_container .add_new_folder{
    background: none;
    border: none;
    cursor: pointer;
    background-color: #000;
    color: #fff;
    border-radius: 40px;
    padding: 19px 2rem;
}

.client_folders_main_outer_container .container .content_container .add_new_folder_btn_container .add_new_folder .actual_new_client_folder_link{
    text-decoration: none;
    color: #fff;
}

.client_folders_main_outer_container .container .content_container .client_folders_container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;
}

.client_folders_main_outer_container .container .content_container .client_folders_container .single_folder{
    display: flex;
    align-items: center;
    padding: 18px;
    /* border: 1px solid rgb(212, 212, 212); */
    border-radius: 25px;
    background-color: #f7f7f7;
}

.client_folders_main_outer_container .container .content_container .client_folders_container .single_folder .single_folder_leftside .folder_icon_img{
    width: 70px;
    height: 70px;
    margin-right: 20px;
}

/* this class here acts as a container for the full folder container */
.client_folders_main_outer_container .container .content_container .client_folders_container .single_folder .single_folder_rightside .folder_name{
    text-decoration: none;
    color: #000;
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 10px;
}

.client_folders_main_outer_container .container .content_container .client_folders_container .single_folder:hover{
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.client_folders_main_outer_container .container .content_container .client_folders_container .single_folder .single_folder_rightside .folder_name .actual_folder_name{
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 18px;
}

.client_folders_main_outer_container .container .content_container .client_folders_container .single_folder .single_folder_rightside .folder_name .user_who_uploaded{
    font-weight: 300;
}

.client_folders_main_outer_container .container .content_container .client_folders_container .single_folder .single_folder_rightside .folder_name .date_when_folder_created{
    font-weight: 300;
}