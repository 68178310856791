*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main_team_chat_page_outer_container .container{
    display: flex;
    /* display: grid;
    grid-template-columns: 1fr 1.5fr 4fr; */
    /* gap: 20px; */
    width: 100%;
    height: 100vh;

    position: relative;
}

.main_team_chat_page_outer_container .container .smaller_side_navbar_container{
    margin-right: 80px;
}

.main_team_chat_page_outer_container .container .current_team_members_container{
    border: 2px solid #000;
    width: 400px;
    margin-right: 20px;
    padding: 20px 10px;
    /* width: 100%; */


    position: absolute; /* Position absolute to fix it */
    top: 0; /* Align to top of container */
    bottom: 0; /* Expand to the bottom of the container */
    overflow-y: auto;
    left: 80px;
}

.main_team_chat_page_outer_container .container .current_team_members_container .search_input_container input{
    margin-bottom: 25px;
    width: 100%;
    padding: 15px 1rem;
    font-size: 17px;
    /* margin: 10px 20px; */
}

.main_team_chat_page_outer_container .container .current_team_members_container .team_members_container{
    display: flex;
    flex-direction: column;
    height: fit-content;
    overflow-y: auto;
}

.main_team_chat_page_outer_container .container .current_team_members_container .team_members_container .single_team_member{
    display: flex;
    align-items: center;
    padding: 15px;
    border: 1px solid rgb(212, 212, 212);
    cursor: pointer;
    margin-bottom: 20px;
}

.main_team_chat_page_outer_container .container .current_team_members_container .team_members_container .single_team_member:hover{
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.main_team_chat_page_outer_container .container .current_team_members_container .team_members_container .single_team_member .single_team_member_leftside .user_profile_img{
    width: 60px;
    height: 60px;
    margin-right: 10px;
    border-radius: 40px;
    cursor: pointer;
}

.main_team_chat_page_outer_container .container .current_team_members_container .team_members_container .single_team_member .single_team_member_rightside .user_username{
    text-decoration: none;
    color: #000;
}

.main_team_chat_page_outer_container .container .chat_messages_container{
    position: fixed;
    top: 0;
    left: 490px;
    /* border: 2px solid #000; */

    /* width: 100%; */
}

.main_team_chat_page_outer_container .container .chat_messages_container .actual_messages_container{
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.main_team_chat_page_outer_container .container .chat_messages_container .actual_messages_container .single_message{
    display: flex;
    align-items: center;
    border: 1px solid rgb(212, 212, 212);
    padding: 12px;
    margin-bottom: 20px;
}

.main_team_chat_page_outer_container .container .chat_messages_container .actual_messages_container .single_message .single_message_leftside .user_message_profile_img{
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin-right: 10px;
}

.main_team_chat_page_outer_container .container .chat_messages_container .actual_messages_container .single_message .single_message_rightside p{
    font-weight: 350;
    line-height: 30px;
}

.main_team_chat_page_outer_container .container .chat_messages_container .add_new_messages_container{
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 10px;
    z-index: 999;
}