.poppins-thin {
	font-family: "Poppins", sans-serif;
	font-weight: 100;
	font-style: normal;
}

.poppins-thin-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 100;
	font-style: italic;
}

.poppins-extralight {
	font-family: "Poppins", sans-serif;
	font-weight: 200;
	font-style: normal;
}

.poppins-extralight-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 200;
	font-style: italic;
}

.poppins-light {
	font-family: "Poppins", sans-serif;
	font-weight: 300;
	font-style: normal;
}

.poppins-light-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 300;
	font-style: italic;
}

.poppins-regular {
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	font-style: normal;
}

.poppins-regular-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	font-style: italic;
}

.poppins-medium {
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-style: normal;
}

.poppins-medium-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-style: italic;
}

.poppins-semibold {
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	font-style: normal;
}

.poppins-semibold-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	font-style: italic;
}

.poppins-bold {
	font-family: "Poppins", sans-serif;
	font-weight: 700;
	font-style: normal;
}

.poppins-bold-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 700;
	font-style: italic;
}

.poppins-extrabold {
	font-family: "Poppins", sans-serif;
	font-weight: 800;
	font-style: normal;
}

.poppins-extrabold-italic {
	font-family: "Poppins", sans-serif;
	font-weight: 800;
	font-style: italic;
}

.poppins-black {
	font-family: "Poppins", sans-serif;
	font-weight: 900;
	font-style: normal;
}

.poppins-black-italic {

	font-family: "Poppins", sans-serif;
	font-weight: 900;
	font-style: italic;
}
