*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main_client_files_outer_container .container .content_container{
    margin-left: 270px;
    margin-right: 15px;
}

.main_client_files_outer_container .container .content_container h2{
    margin-bottom: 1.2rem;
}

.main_client_files_outer_container .container .content_container .add_new_file_btn_container .add_new_file_btn{
    background: none;
    border: none;
    color: #fff;
    border-radius: 40px;
    cursor: pointer;
    background-color: #000;
    padding: 15px 2rem;
    margin-bottom: 2rem;
}

.main_client_files_outer_container .container .content_container .add_new_file_btn_container .add_new_file_btn .new_clientfile_actual_link{
    text-decoration: none;
    color: #fff;
}

.main_client_files_outer_container .container .content_container .client_files_container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    gap: 20px;
}

.main_client_files_outer_container .container .content_container .client_files_container .client_single_file{
    padding: 15px;
    /* border: 1px solid rgb(212, 212, 212); */
    border-radius: 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #f7f7f7;
}

.main_client_files_outer_container .container .content_container .client_files_container .client_single_file:hover{
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.main_client_files_outer_container .container .content_container .client_files_container .client_single_file .file_left_side .file_icon_img{
    width: 60px;
    height: 60px;
    margin-right: 15px;
}

.main_client_files_outer_container .container .content_container .client_files_container .client_single_file .file_right_side h3{
    margin-bottom: 7px;
    font-weight: 400;
}

.main_client_files_outer_container .container .content_container .client_files_container .client_single_file .file_right_side .user_who_uploaded{
    font-weight: 300;
    margin-bottom: 4px;
}

.main_client_files_outer_container .container .content_container .client_files_container .client_single_file .file_right_side .date_uploaded{
    font-weight: 300;
}