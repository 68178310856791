*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.agency_homepage_outer_main_container .container .main_content_container{
    margin-left: 270px;
    margin-right: 15px;
    /* height: 100vh; */
}

.agency_homepage_outer_main_container .container .main_content_container .add_client_container_btn_container{
    margin-top: 1.5rem;
}

.agency_homepage_outer_main_container .container .main_content_container .add_client_container_btn_container .add_client_container_btn{
    padding: 20px 40px;
    background: none;
    border: none;
    cursor: pointer;
    background-color: #000;
    border-radius: 40px;
    color: #fff;
}

.agency_homepage_outer_main_container .container .main_content_container .add_client_container_btn_container .add_client_container_btn .actual_new_client_container_link{
    text-decoration: none;
    color: #fff;
}

.agency_homepage_outer_main_container .container .main_content_container .client_containers_container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.agency_homepage_outer_main_container .container .main_content_container .client_containers_container .single_client .actual_container_link{
    display: flex;
    flex-direction: column;
    padding: 25px;
    /* border: 1px solid rgb(212, 212, 212); */
    border-radius: 20px;
    cursor: pointer;
    text-decoration: none;
    color: #000;
    background-color: #f7f7f7;
}

.agency_homepage_outer_main_container .container .main_content_container .client_containers_container .single_client:hover{
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
}

.agency_homepage_outer_main_container .container .main_content_container .client_containers_container .single_client .actual_container_link .client_top_part h2{
    margin-bottom: 15px;
}

.agency_homepage_outer_main_container .container .main_content_container .client_containers_container .single_client .actual_container_link .client_bottom_part .client_description{
    margin-bottom: 18px;
    font-weight: 300;
    line-height: 32px;
}

.agency_homepage_outer_main_container .container .main_content_container .client_containers_container .single_client .actual_container_link .client_bottom_part .client_email{
    margin-bottom: 15px;
    font-weight: 300;
}

.agency_homepage_outer_main_container .container .main_content_container .client_containers_container .single_client .actual_container_link .client_bottom_part .client_budget{
    font-weight: 300;
}