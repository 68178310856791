*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.client_request_detail_page_outer_container .container .content_container{
    margin-left: 270px;
    margin-right: 15px;
}

.client_request_detail_page_outer_container .container .content_container .request_detail_container{
    max-width: 900px;
    margin: 0 auto;
}

.client_request_detail_page_outer_container .container .content_container .request_detail_container .title_container h2{
    font-size: 2rem;
    margin-bottom: 1.2rem;
}

.client_request_detail_page_outer_container .container .content_container .request_detail_container .date_requested{
    font-weight: 300;
    font-size: 17px;
    margin-bottom: 30px;
}

.client_request_detail_page_outer_container .container .content_container .request_detail_container .request_status{
    font-weight: 300;
    font-size: 17px;
    margin-bottom: 1.2rem;
}

.client_request_detail_page_outer_container .container .content_container .request_detail_container .request_body_content{
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
}