*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main_outer_agency_team_settings_container .container .content_container{
    margin-left: 270px;
    margin-right: 16px;
}

.main_outer_agency_team_settings_container .container .content_container h2{
    font-size: 1.8rem;
    margin-bottom: 12px;
}

.main_outer_agency_team_settings_container .container .content_container p{
    font-size: 16px;
    margin-bottom: 2rem;
}

.main_outer_agency_team_settings_container .container .content_container .team_settings_container{
    /* border: 1px solid rgb(212, 212, 212); */
    padding: 25px;
    width: 100%;
    cursor: pointer;
    background-color: #f7f7f7;
}

.main_outer_agency_team_settings_container .container .content_container .team_settings_container h3{
    margin-bottom: 20px;
    font-weight: 400;
}

.main_outer_agency_team_settings_container .container .content_container .team_settings_container:hover{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
}