*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.main_join_new_agency_outer_container .container .content_container{
    margin-left: 270px;
    margin-right: 15px;
}

.main_join_new_agency_outer_container .container .content_container h2{
    margin-bottom: 2.2rem;
}

.main_join_new_agency_outer_container .container .content_container .form_container{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.main_join_new_agency_outer_container .container .content_container .form_container .single_input{
    width: 100%;
    margin-bottom: 35px;
    display: flex;
    flex-direction: column;
    /* border: 1px solid rgb(212, 212, 212); */
}

.main_join_new_agency_outer_container .container .content_container .form_container .single_input input{
    padding: 17px 1.2rem;
    font-size: 17px;
    border: 1px solid rgb(212, 212, 212);
}

.main_join_new_agency_outer_container .container .content_container .form_container .join_team_btn_container .join_team_btn{
    padding: 20px 2.5rem;
    background: none;
    border: none;
    background-color: #000;
    color: #fff;
    cursor: pointer;
    border-radius: 40px;
}