*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main_slect_type_outer_container .container{
    max-width: 1000px;
    margin: 0 auto;
}

.main_slect_type_outer_container .container .top_navbar_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(212, 212, 212);
    padding: 1.5rem;
    margin-bottom: 2rem;
}

.main_slect_type_outer_container .container .top_navbar_container .logo_container h2{
    color: blue;
    cursor: pointer;
}

.main_slect_type_outer_container .container .top_navbar_container .navbar_container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.main_slect_type_outer_container .container .top_navbar_container .navbar_container li{
    list-style: none;
    margin: 0px 1rem;
}

.main_slect_type_outer_container .container .top_navbar_container .navbar_container li .nav_link{
    text-decoration: none;
    color: #000;
    font-size: 17px;
    font-weight: 350;
}

.main_slect_type_outer_container .container .content_container h2{
    margin-bottom: 1.5rem;
}

.main_slect_type_outer_container .container .content_container .slection_container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
}

.main_slect_type_outer_container .container .content_container .slection_container .single_selection{
    border: 1px solid rgb(212, 212, 212);
    padding: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main_slect_type_outer_container .container .content_container .slection_container .single_selection:hover{
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.main_slect_type_outer_container .container .content_container .slection_container .single_selection .single_selection_top_part .agency_icon_img{
    width: 70px;
    height: 70px;
    /* border-radius: 30px; */
    margin-bottom: 20px;
}

.main_slect_type_outer_container .container .content_container .slection_container .single_selection .single_selection_bottom_part{
    display: flex;
    justify-content: center;
    align-items: center;
} 

.main_slect_type_outer_container .container .content_container .slection_container .single_selection .single_selection_bottom_part .actual_name_link{
    text-decoration: none;
    color: #000;
}