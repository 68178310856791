*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main_outer_create_new_client_request_container .container .content_container{
    margin-left: 270px;
    margin-right: 16px;
}

.main_outer_create_new_client_request_container .container .content_container h2{
    margin-bottom: 1.5rem;
}

.main_outer_create_new_client_request_container .container .content_container .actual_form_container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.main_outer_create_new_client_request_container .container .content_container .actual_form_container .single_input{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.main_outer_create_new_client_request_container .container .content_container .actual_form_container .single_input label{
    margin-bottom: 8px;
}

.main_outer_create_new_client_request_container .container .content_container .actual_form_container .single_input input{
    padding: 18px 1.5rem;
    border: 1px solid rgb(212, 212, 212);
    font-size: 17px;
    font-weight: 300;
}

.main_outer_create_new_client_request_container .container .content_container .actual_form_container .single_input .short_description_input{
    height: 160px;
    padding: 1.8rem;
    font-size: 17px;
    font-weight: 300;
}

.main_outer_create_new_client_request_container .container .content_container .actual_form_container .single_input .request_body_input{
    height: 300px;
    padding: 1.8rem;
    font-size: 17px;
    font-weight: 300;
}

.main_outer_create_new_client_request_container .container .content_container .actual_form_container .upload_request_btn_container .upload_request_btn{
    padding: 18px 2rem;
    border: none;
    background: none;
    color: #fff;
    background-color: #000;
    cursor: pointer;
    border-radius: 40px;
    margin-bottom: 2rem;
}