*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main_outer_demo_vid_page_container .container{
    max-width: 1000px;
    margin: 0 auto;
    border: 2px solid #000;
    margin-top: 5rem;
}

.main_outer_demo_vid_page_container .container video{
    width: 100%;
    height: 100%;
}